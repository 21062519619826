<template>
  <div>
    <b-card>
       <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Nome -->
          <validation-provider
            #default="validationContext"
            name="Nome"
            rules="required"
          >
            <b-form-group
              label="Nome"
              label-for="nome"
            >
              <b-form-input
                id="nome"
                v-model="credorData.nome"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- CNPJ -->
          <validation-provider
            #default="validationContext"
            name="CNPJ"
            rules=""
          >
            <b-form-group
              label="CNPJ"
              label-for="cnpj"
            >
              <b-form-input
                id="cnpj"
                v-model="credorData.cnpj"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Código -->
          <validation-provider
            #default="validationContext"
            name="Codigo"
            rules="required|alpha-num"
          >
            <b-form-group
              label="Codigo"
              label-for="codigo"
            >
              <b-form-input
                id="codigo"
                v-model="credorData.codigo"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group> 
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="credorData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Telefone -->
          <validation-provider
            #default="validationContext"
            name="Telefone"
            rules="required"
          >
            <b-form-group
              label="Telefone"
              label-for="telefone"
            >
              <b-form-input
                id="telefone"
                v-model="credorData.telefone"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Situação -->
          <validation-provider
            #default="validationContext"
            name="Situacao"
            rules="required"
          >
            <b-form-group
              label="Situacação"
              label-for="situacao"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="credorData.situacao"
                :options="situacaoOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="situacao"
                state="false"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="$router.go(-1)"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>     
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BRow,BCol
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref,onBeforeMount } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'
import axios from '@axios'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BCard,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email
    }
  },
  setup(props, { emit }) {
    const blankCredorData = {
      nome: null,
      cnpj: null,
      codigo: null,
      email: null,
      telefone: null,
      situacao: null
    }

    const situacaoOptions = [
      { label: 'Ativo', value: 'ATIVO' },
      { label: 'Bloqueado', value: 'BLOQUEADO' },
      { label: 'Cancelado', value: 'CANCELADO' }
    ]

    const credorData = ref(JSON.parse(JSON.stringify(blankCredorData)))

    const resetCredorData = () => {
      credorData.value = JSON.parse(JSON.stringify(blankCredorData))
    }

    onBeforeMount(() => {
      axios
      .get('credores/'+router.currentRoute.params.id)
      .then(response => {
        const { data } = response.data  
        credorData.value = data
      })
      .catch(error => reject(error))  
    })

    function onSubmit() {
     axios
      .put('credores/'+router.currentRoute.params.id,{
        ...credorData.value
      })
      .then(response => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Alterar credor',
            icon: 'UserIcon',
            variant: 'success',
            text: `Alterado com sucesso!`,
          },
        })
        router.go(-1)
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Alterar',
            icon: 'XIcon',
            variant: 'danger',
            text: `Algo deu errado ):`,
          },
        })
      })  
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCredorData)

    return {
      credorData,
      onSubmit,
      situacaoOptions,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
